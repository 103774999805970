import Promise from 'promise-polyfill';

import type { MerchantsResponse } from '../../types/MerchantsResponse';
import getHawklinksStatus from '../../utils/getHawklinksStatus';
import { getSite } from '../../utils/getSite';
import { MerchantDomain } from '../../utils/merchants';

import { getJSON } from './ajax';
import getKeywords from './getKeywords';
import getUrlParameters from './getUrlParameters';
import init from './init';

export const callback = async (): Promise<void> => {
  // If hawklinks not blocked
  const keywords = await getKeywords();
  if (!getHawklinksStatus(keywords)) {
    const site = getSite(document.location.href, '', keywords);
    window.Promise = window.Promise || Promise;
    const urlParameters = getUrlParameters(['area']);
    const endpoint = window.hawk_api_endpoint || process.env.SEARCH_API_ENDPOINT;

    try {
      const {
        merchants,
        area,
        config = {} as MerchantsResponse['config'],
      } = await getJSON<MerchantsResponse>(`${endpoint}/merchant-domains.php`, {
        area: urlParameters.area,
        site: site.code && site.code.toUpperCase(),
      });

      // Initialise HawkLinks
      await init({
        site,
        merchants,
        area,
        tsid: config.tsid,
        skimlinksId: config.skimlinks_id,
      });
    } catch (_) {
      // Initialise HawkLinks
      await init({
        site,
        merchants: [] as MerchantDomain[],
        area: '',
      });
    }
  }
};
