import { Site } from '../../types/Site';

const responsiveVanillaSelector = '#article-body a, #gallery-body a, .article-body a';
const progressiveVanillaSelector = '.article__body a, #article-body a, .article-body a';
const forumSelector = '.p-body-pageContent a';

const sites: Site[] = [
  {
    code: 'trd',
    url: 'techradar.com',
    name: 'techradar',
    vanillaName: 'techradar',
    analyticsName: 'trd',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48894329-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'grd',
    url: 'gamesradar.com',
    name: 'gamesradar',
    vanillaName: 'gamesradar',
    analyticsName: 'grd',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48899774-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 't3',
    url: 't3.com',
    name: 't3',
    vanillaName: 't3',
    analyticsName: 't3',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-48902248-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'pcg',
    url: 'pcgamer.com',
    name: 'pcgamer',
    vanillaName: 'pcgamer',
    analyticsName: 'pcg',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48457802-19',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'cbq',
    url: 'creativebloq.com',
    name: 'creativebloq',
    vanillaName: 'creativebloq',
    analyticsName: 'cbq',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48905440-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'mrd',
    url: 'musicradar.com',
    name: 'musicradar',
    vanillaName: 'musicradar',
    analyticsName: 'mrd',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48903850-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'theradar',
    url: 'theradar.com',
    name: 'theradar',
    analyticsName: 'theradar',
    vanillaName: 'theradar',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48457802-60',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'dcw',
    url: 'digitalcameraworld.com',
    name: 'digitalcameraworld',
    vanillaName: 'digitalcameraworld',
    analyticsName: 'dcw',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48457802-35',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'realhomes',
    url: 'realhomes.com',
    name: 'realhomes',
    analyticsName: 'realhomes',
    vanillaName: 'realhomes',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-1555272-34',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'homebuilding',
    url: 'homebuilding.co.uk',
    name: 'homebuilding',
    analyticsName: 'homebuilding',
    vanillaName: 'homebuilding',
    selector: `article a, ${responsiveVanillaSelector}`,
    ga: {
      id: 'UA-1555272-7',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'loudersound',
    url: 'loudersound.com',
    name: 'loudersound',
    analyticsName: 'loudersound',
    vanillaName: 'loudersound',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-49681863-18',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'guitarworld',
    url: 'guitarworld.com',
    name: 'guitarworld',
    analyticsName: 'guitarworld',
    vanillaName: 'guitarworld',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-6661635-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'itproportal',
    url: 'itproportal.com',
    name: 'itproportal',
    analyticsName: 'itproportal',
    vanillaName: 'itproportal',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-460866-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'whathifi',
    url: 'whathifi.com',
    name: 'whathifi',
    analyticsName: 'whathifi',
    vanillaName: 'whathifi',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-903754-4',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'practicalcaravan',
    url: 'practicalcaravan.com',
    name: 'practicalcaravan',
    analyticsName: 'practicalcaravan',
    selector: 'article a',
    ga: {
      id: 'UA-12090428-1',
      sendPageView: true,
      format: null,
    },
  },
  {
    code: 'practicalmotorhome',
    url: 'practicalmotorhome.com',
    name: 'practicalmotorhome',
    analyticsName: 'practicalmotorhome',
    selector: 'article a',
    ga: {
      id: 'UA-12090428-2',
      sendPageView: true,
      format: null,
    },
  },
  {
    code: 'tomshardware',
    url: 'tomshardware.com',
    name: 'tomshardware',
    analyticsName: 'tomshardware',
    vanillaName: 'tomshardware',
    selector: `${responsiveVanillaSelector}, ${forumSelector}`,
    ga: {
      id: 'UA-72111741-12',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'tomsguide',
    url: 'tomsguide.com',
    name: 'tomsguide',
    analyticsName: 'tomsguide',
    vanillaName: 'tomsguide',
    selector: `${responsiveVanillaSelector}, ${forumSelector}, #widgetArea12 a.visit-site-test`,
    ga: {
      id: 'UA-72111741-10',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'toptenreviews',
    url: 'toptenreviews.com',
    name: 'toptenreviews',
    analyticsName: 'toptenreviews',
    vanillaName: 'toptenreviews',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-72111741-22',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'anandtech',
    url: 'anandtech.com',
    name: 'anandtech',
    analyticsName: 'anandtech',
    selector: '.articleContent a, .p-body-content a', // Sites, Forums
    ga: {
      id: 'UA-87763359-9',
      sendPageView: true,
      format: null,
    },
  },
  {
    code: 'laptopmag',
    url: 'laptopmag.com',
    name: 'laptopmag',
    analyticsName: 'laptopmag',
    vanillaName: 'laptopmag',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-72111741-6',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'avnetwork',
    url: 'avnetwork.com',
    name: 'avnetwork',
    analyticsName: 'avnetwork',
    vanillaName: 'avnetwork',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-1208129-57',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'techlearning',
    url: 'techlearning.com',
    name: 'techlearning',
    analyticsName: 'techlearning',
    vanillaName: 'techlearning',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-1208129-2',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'space',
    url: 'space.com',
    name: 'space',
    analyticsName: 'space',
    vanillaName: 'space',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-72111741-9',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'livescience',
    url: 'livescience.com',
    name: 'livescience',
    analyticsName: 'livescience',
    vanillaName: 'livescience',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-72111741-7',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'cyclingnews',
    url: 'cyclingnews.com',
    name: 'cyclingnews',
    analyticsName: 'cyclingnews',
    vanillaName: 'cyclingnews',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48905626-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'bikeperfect',
    url: 'bikeperfect.com',
    name: 'bikeperfect',
    analyticsName: 'bikeperfect',
    vanillaName: 'bikeperfect',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-87763359-15',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: '5gradar',
    url: '5gradar.com',
    name: '5gradar',
    analyticsName: '5gradar',
    vanillaName: '5gradar',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48894329-3',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'tvtechnology',
    url: 'tvtechnology.com',
    name: 'tvtechnology',
    analyticsName: 'tvtechnology',
    vanillaName: 'tvtechnology',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-2342357-10',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'fourfourtwo',
    url: 'fourfourtwo.com',
    name: 'fourfourtwo',
    analyticsName: 'fourfourtwo',
    vanillaName: 'fourfourtwo',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-19571072-10',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'guitarplayer',
    url: 'guitarplayer.com',
    name: 'guitarplayer',
    analyticsName: 'guitarplayer',
    vanillaName: 'guitarplayer',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-2342357-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'gardeningetc',
    url: 'gardeningetc.com',
    name: 'gardeningetc',
    analyticsName: 'gardeningetc',
    vanillaName: 'gardeningetc',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-1555272-39',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'nexttv',
    url: 'nexttv.com',
    name: 'nexttv',
    analyticsName: 'nexttv',
    vanillaName: 'nexttv',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-92042047-2',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'techlearninguniversity',
    url: 'techlearninguniversity.com',
    name: 'techlearninguniversity',
    vanillaName: 'techlearninguniversity',
    analyticsName: 'techlearninguniversity',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-92068204-2',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'womanandhome',
    url: 'womanandhome.com',
    name: 'womanandhome',
    analyticsName: 'womanandhome',
    vanillaName: 'womanandhome',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-11429242-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'marieclaire',
    url: 'marieclaire.co.uk',
    name: 'marieclaire',
    analyticsName: 'marieclaire',
    vanillaName: 'marieclaireuk',
    selector: `${progressiveVanillaSelector}, article a, #ise-root a`, // Standard articles & Marie Claire Edit
    ga: {
      id: 'UA-11429130-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'marieclaireusshop',
    url: 'marieclaire.com/shop',
    name: 'marieclaireusshop',
    vanillaName: 'marieclaireusedit',
    analyticsName: 'marieclaireusshop',
    selector: progressiveVanillaSelector, // Marie Claire US
    ga: {
      id: 'UA-198556563-1',
      sendPageView: false,
      format: null,
    },
    urlStage: 'marieclaire.com.master.van-qa.futureplc.engineering/shop/',
  },
  {
    code: 'marieclaireus',
    url: 'marieclaire.com',
    name: 'marieclaireus',
    vanillaName: 'marieclaireus',
    analyticsName: 'marieclaireus',
    selector: `${progressiveVanillaSelector}, .site-content a, #widgetArea12 a.visit-site-test`, // Marie Claire US
    ga: {
      id: 'UA-198556563-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'advnture',
    url: 'advnture.com',
    name: 'advnture',
    vanillaName: 'advnture',
    analyticsName: 'advnture',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48457802-63',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'whattowatch',
    url: 'whattowatch.com',
    name: 'whattowatch',
    vanillaName: 'whattowatch',
    analyticsName: 'whattowatch',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48457802-64',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'goodtoknow',
    url: 'goodto.com',
    name: 'goodtoknow',
    vanillaName: 'goodto',
    analyticsName: 'goodtoknow',
    selector: `${progressiveVanillaSelector}, article a`,
    ga: {
      id: 'UA-11429543-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'livingetc',
    url: 'livingetc.com',
    name: 'livingetc',
    analyticsName: 'livingetc',
    vanillaName: 'livingetc',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-11429207-9',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'fitandwell',
    url: 'fitandwell.com',
    name: 'fitandwell',
    vanillaName: 'fitandwell',
    analyticsName: 'fitandwell',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-48457802-62',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'idealhome',
    url: 'idealhome.co.uk',
    name: 'idealhome',
    vanillaName: 'idealhome',
    analyticsName: 'idealhome',
    selector: `${progressiveVanillaSelector}, .articleBody a, .amp-wp-article-content a`,
    ga: {
      id: 'UA-11429207-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'cyclingweekly',
    url: 'cyclingweekly.com',
    name: 'cyclingweekly',
    vanillaName: 'cyclingweekly',
    analyticsName: 'cyclingweekly',
    selector: `${progressiveVanillaSelector}, .articleBody a`,
    ga: {
      id: 'UA-11432820-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'decanter',
    url: 'decanter.com',
    name: 'decanter',
    analyticsName: 'decanter',
    selector: '.articleBody a',
    ga: {
      id: 'UA-11430006-1',
      format: 'ti',
      sendPageView: false,
    },
  },
  {
    code: 'petsradar',
    url: 'petsradar.com',
    name: 'petsradar',
    vanillaName: 'petsradar',
    analyticsName: 'petsradar',
    selector: responsiveVanillaSelector,
    ga: {
      id: 'UA-48457802-65',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'golfmonthly',
    url: 'golfmonthly.com',
    name: 'golfmonthly',
    vanillaName: 'golfmonthly',
    analyticsName: 'golfmonthly',
    selector: `${progressiveVanillaSelector}, #content a`,
    ga: {
      id: 'UA-11430160-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'homesandgardens',
    url: 'homesandgardens.com',
    name: 'homesandgardens',
    vanillaName: 'homesandgardens',
    analyticsName: 'homesandgardens',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-11429207-10',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'mbr',
    url: 'mbr.co.uk',
    name: 'mbr',
    analyticsName: 'mbr',
    selector: '#content a',
    ga: {
      id: 'UA-11430231-1',
      format: 'ti',
      sendPageView: false,
    },
  },
  {
    code: 'myimperfectlife',
    url: 'myimperfectlife.com',
    name: 'myimperfectlife',
    vanillaName: 'myimperfectlife',
    analyticsName: 'myimperfectlife',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-48457802-68',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'wp',
    url: 'windowscentral.com',
    name: 'windowscentral',
    vanillaName: 'windowscentral',
    analyticsName: 'wp',
    selector: `${responsiveVanillaSelector}, article a`,
    ga: {
      id: 'UA-1058506-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'ac',
    url: 'androidcentral.com',
    name: 'androidcentral',
    vanillaName: 'androidcentral',
    analyticsName: 'ac',
    selector: `${responsiveVanillaSelector}, article a`,
    ga: {
      id: 'UA-5727237-3',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'im',
    url: 'imore.com',
    name: 'imore',
    vanillaName: 'imore',
    analyticsName: 'im',
    selector: `${responsiveVanillaSelector}, article a`,
    ga: {
      id: 'UA-2017486-7',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'cinemablend',
    url: 'cinemablend.com',
    name: 'cinemablend',
    vanillaName: 'cinemablend',
    analyticsName: 'cinemablend',
    selector: `${responsiveVanillaSelector}, article a`,
    ga: {
      id: 'UA-1237708-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'horseandhound',
    url: 'horseandhound.co.uk',
    name: 'horseandhound',
    analyticsName: 'horseandhound',
    selector: '.post-main__inner a',
    ga: {
      id: 'UA-11430035-1',
      format: 'ti',
      sendPageView: false,
    },
  },
  {
    code: 'countrylife',
    url: 'countrylife.co.uk',
    name: 'countrylife',
    analyticsName: 'countrylife',
    vanillaName: 'countrylife',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-11429912-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'ybw',
    url: 'ybw.com',
    name: 'ybw',
    analyticsName: 'ybw',
    selector: '#content a',
    ga: {
      id: 'UA-11432498-1',
      format: 'ti',
      sendPageView: false,
    },
  },
  {
    code: 'rugbyworld',
    url: 'rugbyworld.com',
    name: 'rugbyworld',
    analyticsName: 'rugbyworld',
    selector: '#content a',
    ga: {
      id: 'UA-20051207-1',
      format: 'ti',
      sendPageView: false,
    },
  },
  {
    code: 'mby',
    url: 'mby.com',
    name: 'mby',
    analyticsName: 'mby',
    selector: '#content a',
    ga: {
      id: 'UA-11432636-1',
      format: 'ti',
      sendPageView: false,
    },
  },
  {
    code: 'yachtingmonthly',
    url: 'yachtingmonthly.com',
    name: 'yachtingmonthly',
    analyticsName: 'yachtingmonthly',
    selector: '#content a',
    ga: {
      id: 'UA-11432488-1',
      format: 'ti',
      sendPageView: false,
    },
  },
  {
    code: 'pbo',
    url: 'pbo.co.uk',
    name: 'pbo',
    analyticsName: 'pbo',
    selector: '#content a',
    ga: {
      id: 'UA-11544575-1',
      format: 'ti',
      sendPageView: false,
    },
  },
  {
    code: 'yachtingworld',
    url: 'yachtingworld.com',
    name: 'yachtingworld',
    analyticsName: 'yachtingworld',
    selector: '#content a',
    ga: {
      id: 'UA-11432468-1',
      format: 'ti',
      sendPageView: false,
    },
  },
  {
    code: 'themoneyedit',
    url: 'themoneyedit.com',
    name: 'themoneyedit',
    vanillaName: 'themoneyedit',
    analyticsName: 'themoneyedit',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-195435841-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'wallpaper',
    url: 'wallpaper.com',
    name: 'wallpaper',
    vanillaName: 'wallpaper',
    analyticsName: 'wallpaper',
    selector: `${progressiveVanillaSelector}, .bodyLayer a`,
    ga: {
      id: 'UA-67691-2',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'theweekus',
    url: 'theweek.com',
    name: 'theweekus',
    vanillaName: 'theweek',
    analyticsName: 'theweekus',
    selector: `.polaris__app a, ${progressiveVanillaSelector}`,
    ga: {
      id: 'UA-54145327-3',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'coachmag',
    url: 'coachweb.com',
    name: 'coachmag',
    alias: 'coachmag',
    vanillaName: 'coachmag',
    analyticsName: 'coachmag',
    selector: `${responsiveVanillaSelector}, .content a`,
    ga: {
      id: 'UA-4608194-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'moneyweek',
    url: 'moneyweek.com',
    name: 'moneyweek',
    vanillaName: 'moneyweek',
    analyticsName: 'moneyweek',
    selector: `${progressiveVanillaSelector}, .polaris__app a`,
    ga: {
      id: 'UA-1043354-17',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'kiplinger',
    url: 'kiplinger.com',
    name: 'kiplinger',
    vanillaName: 'kiplinger',
    analyticsName: 'kiplinger',
    selector: `${progressiveVanillaSelector}, .polaris__app a`,
    ga: {
      id: 'UA-4337061-4',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'thefield',
    url: 'thefield.co.uk',
    name: 'thefield',
    analyticsName: 'thefield',
    selector: '#content a',
    ga: {
      id: 'UA-11432296-1',
      format: 'ti',
      sendPageView: false,
    },
  },
  {
    code: 'getprice',
    url: 'getprice.com.au',
    name: 'getprice',
    vanillaName: 'getprice',
    analyticsName: 'getprice',
    selector: `${responsiveVanillaSelector}, main a`,
    ga: {
      id: 'UA-833710-11',
      format: 'getprice',
      sendPageView: false,
    },
  },
  {
    code: 'getpricenz',
    url: 'getprice.co.nz',
    name: 'getpricenz',
    analyticsName: 'getpricenz',
    selector: 'main a',
    ga: {
      id: 'UA-833710-11',
      format: 'getprice',
      sendPageView: false,
    },
  },
  {
    code: 'gardeningknowhow',
    url: 'gardeningknowhow.com',
    name: 'gardeningknowhow',
    vanillaName: 'gardeningknowhow',
    analyticsName: 'gardeningknowhow',
    selector: progressiveVanillaSelector,
    ga: null,
  },
  {
    code: 'pricepandasg',
    url: 'pricepanda.com.sg',
    name: 'pricepandasg',
    analyticsName: 'pricepandasg',
    selector: 'main a',
    ga: {
      id: 'UA-54261143-8',
      format: 'pricepandasgmyph',
      sendPageView: false,
    },
  },
  {
    code: 'pricepandamy',
    url: 'pricepanda.com.my',
    name: 'pricepandamy',
    analyticsName: 'pricepandamy',
    selector: 'main a',
    ga: {
      id: 'UA-54261143-6',
      format: 'pricepandasgmyph',
      sendPageView: false,
    },
  },
  {
    code: 'pricepandaph',
    url: 'pricepanda.com.ph',
    name: 'pricepandaph',
    analyticsName: 'pricepandaph',
    selector: 'main a',
    ga: {
      id: 'UA-54261143-7',
      format: 'pricepandasgmyph',
      sendPageView: false,
    },
  },
  {
    code: 'pricepandaid',
    url: 'pricepanda.co.id',
    name: 'pricepandaid',
    analyticsName: 'pricepandaid',
    selector: 'main a',
    ga: {
      id: 'UA-54261143-3',
      format: 'pricepandaidth',
      sendPageView: false,
    },
  },
  {
    code: 'pricepandath',
    url: 'pricepanda.co.th',
    name: 'pricepandath',
    analyticsName: 'pricepandath',
    selector: 'main a',
    ga: {
      id: 'UA-54261143-9',
      format: 'pricepandaidth',
      sendPageView: false,
    },
  },
  {
    code: 'discover',
    url: 'discover.futureplc.com',
    name: 'discover',
    vanillaName: 'discover',
    analyticsName: 'discover',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-48631151-24',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'mozo',
    url: 'mozo.com.au',
    name: 'mozo',
    analyticsName: 'mozo',
    selector: '[data-hawklinks] a',
    ga: {
      id: 'UA-5405447-1',
      sendPageView: false,
      format: 'mozo',
    },
  },
  {
    code: 'whowhatwear',
    url: 'whowhatwear.co',
    name: 'whowhatwear',
    vanillaName: 'whowhatwear',
    analyticsName: 'whowhatwear',
    selector: `${progressiveVanillaSelector}, #mainarticle a`,
    alias: 'whowhatwearpromo',
    urlStage: 'whowhatwear-promo.com',
    ga: {
      id: 'UA-1024221-1',
      sendPageView: false,
      format: null,
    },
  },
  {
    code: 'itprous',
    url: 'itpro.com',
    name: 'itprous',
    analyticsName: 'itprous',
    selector: `${responsiveVanillaSelector}, .polaris__app a`,
    ga: {
      id: 'UA-1011119-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'myvouchercodes',
    url: 'myvouchercodes.co.uk',
    name: 'myvouchercodes',
    vanillaName: 'myvouchercodes',
    analyticsName: 'myvouchercodes',
    selector: progressiveVanillaSelector,
    ga: {
      id: 'UA-1954547-1',
      format: null,
      sendPageView: false,
    },
  },
  {
    code: 'shortlist',
    url: 'shortlist.com',
    name: 'shortlist',
    analyticsName: 'shortlist',
    selector: '#content a',
    ga: {
      id: 'UA-18349334-2',
      format: typeof window !== 'undefined' && window.dfp_config ? null : 'shortlist',
      sendPageView: false,
    },
  },
];
export default sites;
